<template>
  <div v-if="loaded" id="homepage" class="home" :style="{backgroundImage: `url(${homeBackground})`}">
	<div class="inner_wrapper">

	<div id="top_wrapper">
		<Vue3Marquee class="top_banner" v-if="topNewsflash && topNewsflash.length > 0" direction="reverse" duration="35" >
			<p v-for="news in topNewsflash" :key="news.id" v-html="news.text"></p>
		</Vue3Marquee>
		<p class="no_news top_banner" v-else>אין מודעות להצגה</p>
		<div class="date_time_wrapper">
			<p class="date" v-html="date"></p>
			<p class="time" v-html="time"></p>
		</div>
	</div>

	<div id="main_wrapper">
	<carousel :wrap-around="true" :autoplay="carousel1Autoplay" :items-to-show="1" >
        <slide v-for="item in gallery1" :key="item.id">
          <div class="carousel_item" :style="{ backgroundImage: `url(${item.url})` }"></div>
        </slide>
        <!-- <template #addons>
          <Navigation />
        </template> -->
      </carousel>

	<carousel :wrap-around="true" :autoplay="carousel2Autoplay" :items-to-show="1" >
        <slide v-for="item in gallery2" :key="item.id">
          <div class="carousel_item" :style="{ backgroundImage: `url(${item.url})` }"></div>
        </slide>
        <!-- <template #addons>
          <Navigation />
        </template> -->
    </carousel>
	</div>
	<div id="bottom_wrapper">
		<Vue3Marquee class="bottom_banner" v-if="bottomNewsflash && bottomNewsflash.length > 0"  direction="reverse" duration="35">
			<p v-for="news in bottomNewsflash" :key="news.id" v-html="news.text"></p>
		</Vue3Marquee>
		<p class="bottom_banner" v-else>אין מודעות להצגה</p>
	</div>

	</div>
  </div>
</template>

<script>

import { Vue3Marquee } from 'vue3-marquee';
import 'vue3-marquee/dist/style.css'
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
	data() {
		return {
		time: new Date().getHours() + ':' + new Date().getMinutes().toString().padStart(2, '0'),
		date: new Date().toLocaleString('he', { month: 'long', day: 'numeric'}),
		windowWidth: window.innerWidth,
		topNewsflash: [],
		bottomNewsflash: [],
		homeBackground: 'https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		gallery1: [],
		gallery2: [],
		clockInterval: null,
		dataInterval: null,
		carousel1Autoplay: 3000,
		carousel2Autoplay: 4000,
		loaded: false
		}
	},
	methods:{
		fetchNewData(){
			this.api({ action: "home" },	(data) => {
			this.topNewsflash = data.data.committee_newsflash;  
			this.bottomNewsflash = data.data.residents_newsflash;
			
			this.gallery1 = data.data.gallery1;
			this.gallery2 = data.data.gallery2;
			
			this.carousel1Autoplay = data.data.carousel1_autoplay_time;
			this.carousel2Autoplay = data.data.carousel2_autoplay_time;

			if(data.data.home_pic) this.homeBackground = data.data.home_pic.pic_parsed;

			this.loaded = true;
			});
		}
	},
	mounted(){
		if(!this.clockInterval) this.clockInterval = setInterval(() => {
			const now = new Date();
			this.time = now.getHours() + ':' + now.getMinutes().toString().padStart(2, '0') + ':' + now.getSeconds().toString().padStart(2, '0');

			this.date = new Date().toLocaleString('he', { month: 'long', day: 'numeric'});
		}, 1000);

		if(!this.dataInterval) this.dataInterval = setInterval(this.fetchNewData, 600000);

		this.fetchNewData();

	},
	unmounted(){
		clearInterval(this.clockInterval);
		clearInterval(this.dataInterval);
	},
	components: {Vue3Marquee,Carousel, Slide},
}
</script>

<style lang="scss">
.top_banner {font-size: 1.5em;}
.bottom_banner {font-size: 1.3em}
	#homepage{

		.vue3-marquee{direction: ltr;}
		.text{width: fit-content;padding: 0 50px;}
		.no_news{flex: 1 ;padding:0 20px;}

		&{background-repeat: no-repeat;background-size:cover;background-position:center;overflow: hidden;position: relative;padding: 10vw; font-size: calc(15px + 0.390625vw);}
		@media (max-width: 1920px) {height: 100%;padding: 3vw;}
		@media (min-width: 600px) {}
		&::after{background-color: rgba(0,0,0,0.5);inset: 0;content: "";position: absolute;z-index: 1;}
		.inner_wrapper{
			&{z-index: 99;position: relative;margin: 0 auto;display: flex;flex-direction: column;justify-content: center;height: fit-content;}
			.marquee p, .date_time_wrapper>*{font-size: 1.3em;}
			@media (max-width: 600px) {max-width: 90%;}
			@media (max-width: 1920px) {
				&{max-width: 1200px;}
				.marquee p, .date_time_wrapper>*{font-size: 1.1em;}
			}


			#top_wrapper{
				&{display: flex;align-items: center;margin-bottom: 20px;border-radius: 10px;height: 100px;}
				.date_time_wrapper{white-space: nowrap;flex-basis: 20%;height: 100%;font-size: 1.2em;margin-right: 20px;background-color: var(--color_primary);border-radius: 10px;padding: 10px;text-align: center;}
				.vue3-marquee{background-color: rgba(255, 255, 255, 0.75);height: 100%;border-radius: 10px;}
			}

			#main_wrapper{
				&{display: flex;margin-bottom: 20px;}
				&>*:first-child{margin-left: 30px;}
				.carousel{
					&{flex-basis: 50%;aspect-ratio: 1;overflow: hidden;}
					.carousel_item{
						&{background-repeat: no-repeat;background-size:cover;background-position:center;width: 100%;aspect-ratio: 1;min-height: 35vw;}
						@media (max-width: 1920px) {height: auto;}
					}
				}
			}


			#bottom_wrapper{
				&{background-color: rgba(255, 255, 255, 0.75);padding: 10px;border-radius: 10px;display: flex;}
			}
		}
	}
</style>